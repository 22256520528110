import Vue from 'vue';
import { Prop, Component } from 'vue-property-decorator';
import { Line, mixins } from 'vue-chartjs';

const { reactiveProp } = mixins;

@Component({
  extends: Line,
  mixins: [reactiveProp],
})
export default class LineChart extends Vue {
  public renderChart!: (chartData: any, options: any) => void

  @Prop()
  chartData: any

  @Prop({ default: () => {} })
  options!: object

  mounted() {
    this.renderChart(this.chartData, this.options);
  }
}
