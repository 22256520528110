
























// Components
import { Component, Vue } from 'vue-property-decorator';
import LineChart from '@/components/charts/lineChart';

// GraphQL
import dashboardAppsyncClient from '@/lib/appsync/dashboard';
import getDashboardData from '@/graphql/getDashboardData.graphql';

// Dates
import {
  addMinutes,
  format,
  isSameDay,
  startOfDay,
} from 'date-fns';

import { namespace } from 'vuex-class';
import MainTimeOption from '@/interfaces/mainTimeOption';  // eslint-disable-line
import { formatDate } from '@/utils/dateHelpers'; // eslint-disable-line

const appModule = namespace('appModule');

@Component({
  components: {
    LineChart,
  },
})
export default class BugsnagErrorsChart extends Vue {
  @appModule.Getter
  public currentTimeOption!: MainTimeOption

  public bugsnagErrorChart: {
    labels: string[],
    datasets: {
      backgroundColor: string,
      borderColor?: string,
      data: (number|null)[],
      tension?: number,
      borderWidth?: number,
      fill?: boolean,
      pointRadius?: number,
      pointHitRadius?: number,
      pointHoverRadius?: number,
      pointBackgroundColor?: string,
      pointBorderColor?: string,
    }[],
  } = {
    labels: [],
    datasets: [{
      data: [],
      borderColor: '#2cbbe6',
      backgroundColor: '#2cbbe6',
      tension: 0.1,
      fill: false,
      borderWidth: 1.5,
      pointRadius: 0,
      pointHitRadius: 5,
      pointHoverRadius: 0,
      pointBackgroundColor: '#2cbbe6',
      pointBorderColor: '#2cbbe6',
    }],
  };

  public bugsnagErrorChartOptions = {
    tooltips: {
      callbacks: {
        title: () => '',
      },
    },
    scales: {
      xAxes: [{
        gridLines: {
          display: false,
        },
        ticks: {
          beginAtZero: true,
          fontColor: '#C7C6D7',
          fontStyle: 'bold',
        },
      }],
      yAxes: [{
        ticks: {
          beginAtZero: true,
          fontColor: '#C7C6D7',
          fontStyle: 'bold',
          stepSize: 1,
          callback: (value) => value.toFixed(),
        },
      }],
    },
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
  };

  public loading: boolean = false;

  public async fetchData(): Promise<void> {
    this.loading = true;

    /*
      Fetch central logging
     */
    const {
      data:
        {
          get_dashboard_data: {
            results: bugsnagData,
          },
        },
    } = await dashboardAppsyncClient.query({
      query: getDashboardData,
      variables: {
        input: {
          start_date:
            formatDate(startOfDay(addMinutes(
              new Date(), new Date().getTimezoneOffset() - this.currentTimeOption.value * 1440,
            )), "yyyy-MM-dd'T'HH:mm:ss"),
          end_date: formatDate(startOfDay(addMinutes(new Date(), new Date().getTimezoneOffset())),
            "yyyy-MM-dd'T'HH:mm:ss"),
          env: 'prod',
          record_type: 'bugsnag',
        },
      },
    });
    const labels = Array.from({ length: this.currentTimeOption.value }, (v, k) => format(
      new Date().getTime() - k * 86400000, 'yyyy-MM-dd',
    )).sort((a, b) => new Date(a).getTime() - new Date(b).getTime());
    this.bugsnagErrorChart.labels = labels.map((l) => formatDate(new Date(l), 'MMM d'));
    const parsedData = bugsnagData.map((i) => JSON.parse(i));
    this.bugsnagErrorChart.datasets[0].data = [];
    labels.forEach((label, idx) => {
      this.bugsnagErrorChart.datasets[0].data[idx] = parsedData.filter(
        (i) => isSameDay(new Date(i.received_at), new Date(label)),
      ).length;
    });
    this.loading = false;
  }

  async created() {
    await this.fetchData();
    this.$eventBus.$on('timeAppChanged', () => {
      this.fetchData();
    });
  }
}
